.gettingStartedWholeBox {
  width: 75%;
  animation: slide-in-bottom 0.5s ease;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.hideBox {
  opacity: 0;
}
