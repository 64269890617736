/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      Main    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #343a40;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

:root {
  /* Colors */
  --saic-blue: #016bb5;
  --saic-gray: #212529;
  --saic-darkgray: #343a40;
  --saic-white: #ffffff;
  --saic-border: #9a9da0;

  /* Styles */
  --saic-blur: blur(6px);
  --saic-outradius: 0.5rem;
  --saic-inradius: 0.5rem;

  /* Fonts */
  --saic-heading: 25px;
  --saic-subheading: 18px;

  /* Transitions */
  --saic-hover: 0.35s ease;
}

.h5,
h5 {
  font-size: 1.15rem !important;
  font-weight: 200 !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      Icon Buttons    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: 2em;
  height: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 3rem;
  height: 3rem;
}

img:hover {
  cursor: pointer;
}

tr:hover {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Shadows    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.shadow1 {
  box-shadow: 0px 10px 20px 15px rgba(0, 0, 0, 0.3);
}

.shadow6 {
  background: #2ec194 !important;
  border-radius: 15px;
  box-shadow: -15px -15px 15px rgba(255, 255, 255, 0.2),
    15px 15px 15px rgba(0, 0, 0, 0.1);
}

.request-shadow {
  border-radius: 50%;

  -webkit-box-shadow: -30px 30px 20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -30px 30px 20px rgba(0, 0, 0, 0.3);
  box-shadow: -30px 30px 20px rgba(0, 0, 0, 0.3);
}

.bottom-shadow {
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.3);
}

.tabs-shadow {
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.3);
}

.top-shadow {
  box-shadow: 0px -10px 20px 5px rgba(0, 0, 0, 0.3);
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Scrollbar    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

/* Firefox * {
  scrollbar-width: thin;
  scrollbar-color: #dadce1 #dadce1;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  margin-top: 4rem !important;
  margin-bottom: 2.55rem !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #dadce1;
  border-radius: 10px;
  border: 3px solid #dadce1;
} */

body {
  overflow: overlay !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-player__preview {
  /* background-image: url(https://twc-data.s3-us-gov-west-1.amazonaws.com/Thumbnail.png) !important; */
  border-radius: 0.25rem;
  overflow: hidden;
  border-right: 1.5px solid rgb(154, 157, 160) !important;
}

@media screen and (max-width: 1430px) {
  .react-player__preview {
    border-radius: 0.25rem;
    overflow: hidden;
    border-right: 0px solid rgb(154, 157, 160) !important;
  }
}
