

.header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-control-file {
  margin-bottom: 10px;
}

.btn {
  background-color: red;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #016bb5;
}

.folderSection {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.folderHeader {
  display: flex;
  align-items: center;
}

.folderTitle {
  flex-grow: 1;
  font-size: 24px;
}

.files {
  list-style-type: none;
  padding: 0;
  overflow-y: scroll;
  max-height: 100%;
  margin-bottom: 5rem;
}

.file {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.2s ease;
}

.file.isDirectory {
  font-weight: bold;
}

.file:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;
}

/* ************************************************************************************** */

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-width: unset;
  z-index: 3;
  align-items: center;
}

.editContainer {
  animation: slide-in-bottom 0.5s ease;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-items: center;
  background-color: #212529;
  color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}

.fileUploadCont {
  animation: slide-in-bottom 0.5s ease;
  max-width: 1400px;
  /* width: 900px; */
  height: 50%;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: #212529;
  color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  
}

.fileUploadCont {
  margin: 1rem 0rem 1rem 0rem;
  margin-bottom: 2rem;
}

.boxSelector {
  color: #212529;
}

.boxSelector svg {
  color: #212529;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.header {
  padding: 1rem 0rem 1rem 0rem;
  font-size: 1.5rem;
}

.selectHeader {
  font-size: 1.3rem;
}

.header h2 {
  margin: 0;
}

.content {
  background-color: #212529;
  height: 80%;
  padding: 1rem;
}

.fileSelectCont {
  width: 100%;
  display: flex;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  flex-direction: column;
}

.uploadButton {
  color: #fff;
  height: 40px;
  width: 160px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.25s ease-out;
}

.backButton {
  color: #fff;
  height: 40px;
  width: 160px;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40;
  transition: all 0.25s ease-out;
}

.nextBtn:disabled {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40;
  border-color: rgba(1, 107, 181);
  transition: all 0.25s ease-out;
  filter: brightness(0.7);
}

.fileButtonText {
  width: 676px;
}
