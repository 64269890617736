/* VIPRAccountRequestForm.module.css */

.form {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
}

input,
select,
textarea {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.hidden {
  display: none;
}

.modalContent {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: #212529;
  margin: 10% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
}

.progressBar {
  width: 100%;
  height: 5px;
  background-color: #9a9da0;
  border-radius: 2.5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.progress {
  height: 100%;
  width: 0;
  background-color: #016bb5;
  border-radius: 2.5px;
  transition: width 0.3s ease;
}

.step {
  color: #fff;
  height: 650px;
}

.step.active {
  display: block;
}

h2 {
  color: #fff;
}
.regTitle {
  animation: fadeInDown 0.75s forwards ease;
}
p {
  /* margin-bottom: 25rem; */
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.nextBtn:disabled {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
  filter: brightness(0.7);
}

.nextBtn:disabled:hover {
  color: #fff;
  filter: brightness(0.7);
  transition: all 0.25s ease-out !important;
  cursor: not-allowed;
}

.yesBtn,
.noBtn,
.accountType {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
  margin-top: 1rem;
}

.accountType {
  height: 60px;
}

.yesBtn:hover,
.accountType:hover {
  color: #016bb5;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}

.noBtn:hover {
  color: tomato;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}

.nextBtn {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
}

/* div:has(.noBtn) .nextBtn {
  color: transparent;
  background-color: transparent !important;
  cursor: default;
} */

.nextBtn:hover {
  color: #016bb5;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}

.saicEmployeeContent {
  animation: fadeInDown 0.75s forwards ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 15rem;
}

.step1Content {
  animation: fadeInDown 0.75s forwards ease;
  overflow: hidden;
}

.step2Content {
  animation: fadeInDown 0.75s forwards ease;
}

.step3Content {
  animation: fadeInDown 0.75s forwards ease;
}

.step4Content {
  animation: fadeInDown 0.75s forwards ease;
}

.confirmationPage {
  height: 765px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.step5Content {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitCont {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 250px;
  animation: fadeInDown 0.75s forwards 1s ease;
  opacity: 0;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.checkmark {
  width: 168px;
  height: 168px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.submitText {
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
  max-width: 100%;
  text-align: center;
  animation: fadeInDown 0.75s forwards ease;
}

.cacInfo {
  font-size: 1rem;
  color: #fff;
  padding: 1rem;
  max-width: 100%;
  text-align: center;
}

.noteText {
  margin-top: 1rem;
  font-size: .9rem;
  text-align: center;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 90px #7ac142;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 1rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switchContainer {
  display: flex;
  align-items: center; /* vertically center the label with the switch */
}

.prependedLabel {
  margin-right: 10px; /* spacing between the label and the switch */
  font-size: 16px;
  margin: 1rem;
}
