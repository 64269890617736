.core {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11vw, 1fr));
  grid-gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.core img {
  width: 12vw;
  max-width: 180px;
}

.appHolder {
  /* display: flex; */
  flex-direction: column;
  width: 100%;
  border: 1px solid #9a9da0;
  border-top: 0px;
  min-height: 617px;
  justify-content: center;
  /* align-items: center; */
  border-radius: 0 0 0.5rem 0.5rem;
  backdrop-filter: blur(6px);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
}

.loader {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .core {
    grid-template-columns: repeat(2, 1fr);
  }
}

.core > div:nth-last-child(6) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.3s;
  opacity: 0;
}

.core > div:nth-last-child(5) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.4s;
  opacity: 0;
}

.core > div:nth-last-child(4) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.5s;
  opacity: 0;
}

.core > div:nth-last-child(3) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.6s;
  opacity: 0;
}

.core > div:nth-last-child(2) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.7s;
  opacity: 0;
}

.core > div:nth-last-child(1) {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.8s;
  opacity: 0;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    color: transparent;
    transform: translateY(-75px);
  }
  to {
    opacity: 1;
    color: #fff;
    transform: translateY(0px);
  }
}
