.container {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: #212529;
  border: 1px solid #9a9da0;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.image {
  height: 140px;
}

.title {
  text-align: center;
  font-size: 25px;
  margin: auto;
}
