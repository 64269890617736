.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: #212529;
  border-radius: 0.5rem;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  height: 700px;
  width: 1000px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  animation: fadeInDown 0.5s forwards ease;
}

.enviroments {
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.modal span {
  color: white;
  font-size: 30px;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}

.modal button {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.25rem;
  color: white;
  transition: var(--saic-hover);
  background-color: rgba(255, 255, 255, 0.05);
}

.modal button:hover {
  color: var(--saic-border);
  border-color: var(--saic-blue);
  transition: all 0.3s ease !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.selectGroup {
  height: 500px;
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}

.selectDropdown > button {
  width: 300px;
  background-color: var(--saic-darkgray);
  border-color: var(--saic-blue);
}

.selectDropdown > div {
  width: 300px;
}

.selectInput {
  display: flex;
  width: 300px;
  background-color: var(--saic-darkgray);
  border-color: var(--saic-blue);
  color: var(--saic-white);
}
