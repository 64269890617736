.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  align-items: flex-end;
  padding: 0.5rem;
  padding-bottom: 0;
  border-bottom: 5px solid #016bb5;
  background-color: #212529;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.brandLogo {
  width: 190px;
  margin-right: 0.5rem;
  object-fit: contain;
  font-size: 1.5rem;
  white-space: nowrap;
  color: white;
  margin-left: 0.5rem;
}

.viprHeader {
  width: 190px !important;
}

.navLinks {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0 1rem;
  padding: 0;
  background-color: #343a40;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.navLink {
  padding: 0.5rem;
  align-items: flex-end;
  color: #ffff;
}

.navLink:hover {
  cursor: pointer;
}

.navLink a {
  text-decoration: none;
  color: #ffff;
}

.active {
  background-color: #016bb5;
  color: #ffff;
  box-shadow: 0px -25px 30px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.account {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0 1rem;
  margin-left: auto;
  padding: 0;
}

.account li {
  padding: 0.5rem;
  margin-right: 0.5rem;
}

.account span {
  text-decoration: none;
  color: #ffff;
  line-height: 30px;
}

.account svg {
  color: #ffff;
  height: 30px;
  width: 30px;
}

.account svg:hover {
  cursor: pointer;
}
