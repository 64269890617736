/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Button Motion    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.motion-button {
  visibility: hidden !important;
  height: 100%;
  width: 100%;
}

.motion-button svg {
  visibility: visible;
  color: white;
}

.motion-button > * {
  visibility: visible;
  color: white;
  font-weight: 200 !important;
}

.motion-button-app-stream {
  visibility: hidden !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.motion-button-app-stream svg {
  visibility: visible;
  color: white;
}

.motion-button-app-stream > * {
  visibility: visible;
  color: white;
  font-weight: 200 !important;
  white-space: nowrap !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      Mobile / Skinny   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

@media screen and (min-width: 500px) and (max-width: 810px) {
  .motion-button-app-stream > * {
    font-size: 18px;
    z-index: 1;
  }
}

@media screen and (min-width: 811px) and (max-width: 959px) {
  .motion-button-app-stream > * {
    font-size: 19px;
    z-index: 1;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      1080P Full   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

@media screen and (min-width: 1800px) and (max-width: 2300px) {
  .motion-button-app-stream > * {
    font-size: 25px;
    z-index: 1;
  }
}

/* ▼▼▼   1080P Half   ▼▼▼ */

@media screen and (min-width: 960px) and (max-width: 1000px) {
  .motion-button-app-stream > * {
    font-size: 20px;
    z-index: 1;
  }
}

/* ▼▼▼      1080P More than Half but not full  ▼▼▼ */

@media screen and (min-width: 1001px) and (max-width: 1199px) {
  .motion-button-app-stream > * {
    font-size: 22px;
    z-index: 1;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      2K Full   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

@media screen and (min-width: 2301px) and (max-width: 2561px) {
  .motion-button-app-stream > * {
    font-size: 26px;
    z-index: 1;
  }
}

/* ▼▼▼     2K Half   ▼▼▼ */

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .motion-button-app-stream > * {
    font-size: 26px;
    z-index: 1;
  }
}

/* ▼▼▼     2K More than Half but not full   ▼▼▼ */

@media screen and (min-width: 1451px) and (max-width: 1799px) {
  .motion-button-app-stream > * {
    font-size: 29px;
    z-index: 1;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      4K Full   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

@media screen and (min-width: 2600px) {
  .motion-button-app-stream > * {
    font-size: 30px;
    z-index: 1;
  }
}

/* ▼▼▼      4K Half   ▼▼▼ */

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Chevron    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.how-to-chevron-motion {
  visibility: hidden !important;
  height: 100%;
  width: auto;
}

.how-to-chevron-motion > * {
  visibility: visible;
  color: white;
}
