.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-width: unset;
  z-index: 3;
}

.modalContent {
  max-width: 1250px;
  min-width: 500px;
  max-height: 70vh;
  margin-top: 7rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  position: relative;
  animation: fadeInDown 0.5s forwards;
  align-items: start;
  border-radius: 0.25rem;
  color: white;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.header {
  background-color: #212529;
  padding: 1rem;
  height: 10%;
}

.header h2 {
  margin: 0;
}

.content {
  background-color: #212529;
  height: 80%;
  padding: 1rem;
  overflow: hidden;
  
}

.footer {
  display: flex;
  justify-content: center;
  height: 10%;
  padding: 1rem;
  align-items: flex-end;
  background-color: #212529;
}

.cancelBtn,
.submitBtn {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40;
  border-color: rgba(1, 107, 181);
  transition: all 0.25s ease-out;
}

.submitBtn:hover,
.backButton:hover {
  color: #016bb5;
  filter: brightness(1.1);
  transition: all 0.25s ease-out;
}

.uploadButton:hover {
  color: #00cc44;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.25s ease-out;
}

.cancelBtn:hover {
  color: tomato;
  filter: brightness(1.1);
  transition: all 0.25s ease-out;
}