.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-width: unset;
  z-index: 3;
  align-items: center;
}

.editContainer {
  animation: slide-in-bottom 0.5s ease;
  max-width: 1400px;
  width: 900px;
  height: 70%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  justify-items: center;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: #212529;
  color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  animation: fadeInDown 0.5s forwards ease;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.selectContainer {
  margin: 1rem 0rem 1rem 0rem;
}

.boxSelector {
  color: #212529;
}

.boxSelector > div {
  overflow-y: scroll;
  max-height: 300px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.closeBtn {
  transition: all 0.25s ease-out !important;
}

.closeBtn:hover {
  color: tomato;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}

.boxSelector svg {
  color: #212529 !important;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.header {
  padding: 1rem 0rem 1rem 0rem;
  font-size: 1.5rem;
}

.selectHeader {
  font-size: 1.3rem;
}

.header h2 {
  margin: 0;
}

.content {
  background-color: #212529;
  height: 80%;
  padding: 1rem;
}

.btnContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
}

.nextBtn:disabled {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
  filter: brightness(0.7);
}

.cancelBtn,
.submitBtn {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
}

.submitBtn:hover {
  color: #016bb5;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}

.cancelBtn:hover {
  color: tomato;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}
