/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Header    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.how-to-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
  animation: slide-in-bottom 0.5s ease;

  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

@media screen and (min-width: 2001px) and (max-width: 3000px) {
  .how-to-container {
    width: 70%;
    max-width: 70%;
  }
}

@media screen and (min-width: 500px) and (max-width: 2000px) {
  .how-to-container {
    width: 75%;
    max-width: 75%;
  }
}

@keyframes slide-in-bottom {
  from {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.how-to-header {
  align-items: center;
  padding-top: 0.5rem;
  background-color: #212529;
  min-height: 110px !important;
  top: 10px !important;
  padding: 0.5em;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-bottom: none !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 200 !important;
  flex-direction: column;
  font-size: 1.8rem !important;
  justify-content: center;
  text-align: center;
}

.howto-whole-box {
  min-width: 100%;
  align-items: center;
  height: flex;
  font-size: 1.8rem !important;
  overflow: hidden;
  transition: all var(--speed) ease;
}

.howto-whole-box {
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Content Header    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.how-to-table {
  height: 100vh;
  width: 100%;
}

.how-to-table-pills {
  display: block;
  margin: auto;
  width: 90vw;
}

.how-to-table div tbody {
  margin: auto;
  align-items: center;
}

.howto-title-box {
  background-color: #212529;
  top: 10vw !important;
  padding: 0.5em;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-bottom: none !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 200 !important;
  display: flex;
  font-size: 1.8rem !important;
  width: 100%;
  min-height: 110px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.back-button {
  float: left;
  margin-top: 11px;
}

.folder-title {
  --bs-gutter-x: 0 !important;
  padding-left: 0 !important;
}

.center-title,
.title {
  font-size: 2rem !important;
  font-weight: 200 !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.center-title {
  text-align: center !important;
  align-items: center !important;
  width: 100%;
  --bs-gutter-x: 0 !important;
}

.file-path {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-size: 1.15rem !important;
  font-weight: 200 !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.file-path-link {
  margin-left: 3px;
}

.file-path-link:hover {
  cursor: pointer;
  color: #016bb5;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Content Search    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.search-holder {
  justify-content: flex-end !important;
  max-width: 100%;
}

.button-image {
  font-size: 1.75rem;
}

.search-box-row {
  /* text-align: end;
  vertical-align: bottom !important;
  align-items: flex-end !important;
  --bs-gutter-x: 0 !important;
  padding-left: 0 !important; */

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.howto-search-box {
  background-color: transparent;
  border: 1.5px solid rgb(154, 157, 160);
  border-radius: 2rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  cursor: default !important;

  display: flex;
  align-items: center;
  flex-direction: row;
}
.howto-search-box:hover {
  color: #016bb5;
}

.howto-search-icon {
  font-size: 2rem;
  color: #fff !important;
}

.howto-search-box span {
  max-width: 35rem;
  -webkit-transition: max-width 1s;
  transition: max-width 0.75s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
}

@media (hover: hover) {
  .howto-search-box span {
    max-width: 0;
  }

  .howto-search-box .open span {
    max-width: 35rem;
    background-color: transparent;
  }

  span.input-text-open {
    max-width: 35rem;
    background-color: transparent;
  }
}

.input-text,
.input-text:focus {
  transform: translateX(2%);
  font-size: 1.5rem;
  font-weight: 200;
  background-color: transparent;
  border: 1.5px solid transparent !important;
  border-radius: 0.5rem;
  color: #fff;
  min-width: 0px !important;
  width: 12em;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Content Files    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.file-text {
  position: absolute;
  font-size: 17px;
  bottom: 0;
  margin-bottom: 10%;
  max-height: 90px;
  text-overflow: ellipsis;
}

.howto-file {
  position: relative;
  height: 100%;
}

.file-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.howto-row {
  max-height: 60vh;
  min-height: 60vh;
  min-width: 100% !important;
  width: 100%;
  padding: 2rem;
  border: 1.5px solid rgb(154, 157, 160) !important;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.8rem !important;
  margin-left: 0;
  margin-right: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
}

.howto-row:first-child {
  padding-top: 0.5rem;
}

.howto-row:last-child {
  padding-bottom: 0.5rem;
}

.howto-row::-webkit-scrollbar {
  display: none;
}

.folder-holder {
  text-align: center;
  margin: auto;
}

.folder-holder-parent {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  max-height: 60vh;
  justify-content: center;
}

.howto-folder-menu {
  width: 100%;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.8rem !important;
  margin-left: 0;
  margin-right: 0;
}

.howto-desc-box {
  background-color: #212529;

  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-top: none !important;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.howto-title {
  align-content: flex-start;
  padding-top: 2em;
  font-size: 2.1rem;
}

.howto-info {
  text-align: left;
  padding-bottom: 1em;
  border-left: 1.5px solid rgb(154, 157, 160) !important;
  border-top: 1.5px solid rgb(154, 157, 160) !important;
  border-top-left-radius: 0.6rem;
  font-size: 1.8rem !important;
  font-weight: 200 !important;
}

.howto-buttons {
  height: 12rem;
  margin: 1.5rem;
  max-width: 2rem;
  backdrop-filter: blur(6px);
}

.main-howto-buttons {
  height: 12rem;
  margin: 1rem;
  max-width: 2rem;
}

.howto-folder {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Content Main Menu    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.main-menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  text-decoration: none;
  width: 100%;
}

.acct-nav-item {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.account-menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0rem;
  text-decoration: none;
  width: 100%;
}

a {
  color: #fff;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

.slider-button-one {
  background-color: transparent;
  border: 1.5px solid rgb(154, 157, 160);
  border-radius: 2rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  cursor: default !important;

  display: flex;
  align-items: center;
  flex-direction: row;
}

.slider-button-one span {
  max-width: 35rem;
  -webkit-transition: max-width 1s;
  transition: max-width 0.75s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}

.span-text {
  transform: translateX(2%);
  font-size: 1.1rem;
  font-weight: 200;
}

@media (max-width: 390px) {
  .slider-button-one svg,
  .slider-button-one img {
    display: none;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      How-To Transitions    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.howto-menu-enter {
  opacity: 0;
}
.howto-menu-enter-active {
  opacity: 1;
  transition: all var(--speed) ease;
}
.howto-menu-exit {
  opacity: 0;
}
.howto-menu-exit-active {
  opacity: 1;
  transition: all var(--speed) ease;
}

.transition-holder {
  height: fit-content;
}

.btn-outline-primary {
  min-width: 235px;
  border-color: rgb(154, 157, 160);
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 1.5rem;
  font-weight: 200;
}

.btn:hover {
  background-color: #212529;
  border-color: #016bb5;
}
