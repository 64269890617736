.content {
  background-color: transparent;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2;
  padding-top: 7rem;
  padding-bottom: 4rem;
}
