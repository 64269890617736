.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  width: 90%;
  margin: auto;
}

.grid img {
  width: 5rem;
  margin: 1rem;
}

.grid > div {
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 0.8s;
  opacity: 0;
}

.grid::-webkit-scrollbar {
  display: none;
}

.grid::-webkit-scrollbar-thumb {
  display: none;
}

@media (max-width: 1100px) {
  .grid {
    max-height: 60vh;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    margin: 0;
    overflow-x: hidden;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    color: transparent;
    transform: translateY(-75px);
  }
  to {
    opacity: 1;
    color: #fff;
    transform: translateY(0px);
  }
}
