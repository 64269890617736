/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Main       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.modal-content {
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    border-radius: 15px !important;
  }
  
/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Modal Header       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .confirm-modal-header {
    background-color: #212529 !important;
    color: #ffffff !important;
    padding: 1em !important;
    justify-content: center !important;
    border-color: #dc3545;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Modal Body       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
  
  .confirm-modal-body {
    background-color: #212529;
    color: black;
    align-items: center !important;
    justify-content: center !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-top: none !important;
    border-bottom: none !important;
  }
  
/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Modal Footer       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .confirm-modal-footer {
    background-color: #212529 !important;
    color: #ffffff !important;
    padding: 1em !important;
    justify-content: center !important;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-top: none !important;
    border-bottom-left-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }
  
  .modal-button-body {
    width: 20em;
    justify-content: center !important;
    display: flex !important;
    background-color: #343a40 !important;
    border-color: rgba(1, 107, 181) !important;
    transition: all 0.25s ease-out !important;
    margin: auto !important;
    
  }
  
  .modal-button-body:hover {
    filter: brightness(1.3);
    transition: all 0.25s ease-out !important;
  }
  
  .modal-button {
    width: 13em;
    background-color: #343a40 !important;
    border-color: rgba(1, 107, 181) !important;
    transition: all 0.25s ease-out !important;
  }
  
  .modal-button:hover {
    filter: brightness(1.3);
    transition: all 0.25s ease-out !important;
  }

  .modal-spinner {
    left: 50%;
    right: -50%;
    top: -50%;
  }

  .confirm-modal-body.form {
    min-height: 500px;
  }

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Result       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .result-text {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: 25%;
  }

  .result-success {
    font-size: 50px;
    font-weight: bold;
    color: green;
  }

  .result-requested {
    font-size: 50px;
    font-weight: bold;
    color: yellow;
  }

  .result-error {
    font-size: 50px;
    font-weight: bold;
    color: red;
  }

  .result-primary {
    margin-top: 2rem;
    font-size: 25px;
  }

  .result-primary a {
    font-weight: bold;
    color: gray !important;
  }

  .result-secondary {
    color: gainsboro;
  }

  /* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Feedback       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.explanationInput {
  padding-top: .25rem !important;

}

.feedback-modal-footer {
  background-color: #212529 !important;
  color: #ffffff !important;
  padding: 1em !important;
  justify-content: center !important;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-top: none !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}