.dropdown {
  top: 75px;
  position: fixed;
  overflow: hidden;
  transform: translateX(-275px);
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  animation: linkOut 0.5s 1 ease forwards;
  max-height: 0px;
}

.dropdown.open {
  animation: linkIn 0.5s 1 ease forwards;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
  max-height: 500px;
}

/*prevents animation from running on initial render*/
.dropdown.preventAnimation {
  animation-name: none;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: transparent;
}

.item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  text-decoration: none;
  width: 293px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease !important;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  gap: 1rem;
  padding-left: 0.25rem;
}

.item:hover {
  transition: all 0.3s ease !important;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.item svg {
  color: #fff;
  transition: all 0.3s ease !important;
}

.item:hover svg {
  color: #016bb5;
  transition: all 0.3s ease !important;
}

.accountItem {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.accountItem span {
  font-size: larger;
  font-weight: bold;
}

@keyframes linkIn {
  from {
    max-height: 0px;
    background-color: #212529;
    color: transparent;
  }
  to {
    max-height: 500px;
    background-color: #212529;
    color: #fff;
  }
}

@keyframes linkOut {
  from {
    max-height: 500px;
    background-color: #212529;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  to {
    max-height: 0px;
    background-color: #212529;
    color: transparent;
  }
}
